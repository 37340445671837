import { Flex, Group, PasswordInput, Space, TextInput, createStyles } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import React from 'react'

import { ThatchButton } from '~/components/shared/ThatchButton'
import { RegisterUser } from '~/endpoints/model'

const useStyle = createStyles(theme => ({
  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [`@media (min-width: ${theme.breakpoints.sm})`]: {
      maxWidth: 343,
    },
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  input: {
    fontSize: 22,
    height: 48,
  },
}))

interface RegisterFormProps {
  form: UseFormReturnType<RegisterUser>
  handleSubmit: (user: RegisterUser) => void
  isRegistering: boolean
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  form,
  handleSubmit,
  isRegistering,
}) => {
  const { classes } = useStyle()

  const renderFirstNameInput = () => {
    const commonProps = {
      placeholder: 'First Name',
      h: 48,
      classNames: {
        input: classes.input,
      },
      ...form.getInputProps('firstName'),
    }
    return <TextInput {...commonProps} />
  }

  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      w="100%"
    >
      <form
        onSubmit={form.onSubmit(handleSubmit)}
        autoComplete="new-password"
        className={classes.form}
      >
        <Group
          position="apart"
          grow
        >
          {renderFirstNameInput()}
          <TextInput
            placeholder="Last Name"
            h={48}
            classNames={{
              input: classes.input,
            }}
            {...form.getInputProps('lastName')}
          />
        </Group>
        <TextInput
          mt={28}
          placeholder="Email"
          w="100%"
          autoComplete="new-password"
          h={48}
          classNames={{
            input: classes.input,
          }}
          {...form.getInputProps('email')}
        />
        <PasswordInput
          placeholder="Set Password"
          mt={28}
          h={48}
          w="100%"
          classNames={{
            input: classes.input,
            innerInput: classes.input,
          }}
          autoComplete="new-password"
          {...form.getInputProps('password')}
        />
        <Space h={56} />
        <ThatchButton
          w={256}
          size="mobileLarge"
          type="submit"
          label="Sign up"
          loading={isRegistering}
        />
      </form>
      {process.env.NEXT_PUBLIC_THATCH_ENV !== 'production' && (
        <>
          <Space h={20} />
          <ThatchButton
            w={256}
            size="mobileLarge"
            type="submit"
            label="Random User"
            onClick={async () => {
              const { faker } = await import('@faker-js/faker')
              form.setValues({
                email: faker.internet.email(),
                firstName: faker.name.firstName(),
                lastName: faker.name.lastName(),
                password: 'thatch-web',
              })
            }}
          />
        </>
      )}
    </Flex>
  )
}
