import { ButtonProps } from '@mantine/core'

import { ThatchSignInButton } from '~/components/shared/ThatchSignInButton'
import { LocalImage } from '~/components/shared/image/LocalImage'

import email from 'public/images/social/email.png'

interface EmailButtonProps extends Omit<ButtonProps, 'size'> {
  label: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const EmailButton: React.FC<EmailButtonProps> = ({ label, onClick, ...rest }) => {
  return (
    <ThatchSignInButton
      w={242}
      variant="outline"
      label={label}
      leftIcon={
        <LocalImage
          src={email}
          width={48}
          height={48}
        />
      }
      onClick={onClick}
      {...rest}
    />
  )
}
