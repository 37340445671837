import { Typography } from '~/components/shared/text/Typography'

export const TermsAndServices = ({ source }: { source: string }) => {
  return (
    <Typography
      variant="caption"
      align="center"
      color="appPlaceholder.0"
    >
      Click “{source}” to agree to Thatch’s{' '}
      <Typography
        variant="caption"
        span
        rawLink
        href="https://thatch.notion.site/Terms-of-Service-3062a81446f64df0b4a3ddcd04531de6"
        target="_blank"
        sx={{ cursor: 'pointer', textDecoration: 'underline', textDecorationColor: '#595959' }}
      >
        Terms of Service
      </Typography>
      <br />
      and confirm our{' '}
      <Typography
        variant="caption"
        span
        rawLink
        href="https://thatch.notion.site/Privacy-Policy-408c99bc42c14705a1c8158d74ec5bbe"
        target="_blank"
        sx={{ cursor: 'pointer', textDecoration: 'underline', textDecorationColor: '#595959' }}
      >
        Privacy Policy
      </Typography>{' '}
      applies to you.
    </Typography>
  )
}
