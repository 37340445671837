import { ButtonProps } from '@mantine/core'
import * as Sentry from '@sentry/nextjs'

import { AuthRedirectEventPayload } from '~/analytics/AuthEvent'
import { ThatchSignInButton } from '~/components/shared/ThatchSignInButton'
import { LocalImage } from '~/components/shared/image/LocalImage'
import { notify } from '~/components/shared/notify'
import { useAnalytics } from '~/context/SegmentProvider'
import { signInWithApple } from '~/endpoints/user'

import appleLogo from 'public/images/social/apple.png'

interface AppleSignInProps extends Omit<ButtonProps, 'size'> {
  authRedirectEventPayload: AuthRedirectEventPayload
  label: string
}

export const AppleSignInButton: React.FC<AppleSignInProps> = ({
  authRedirectEventPayload,
  label,
  ...rest
}) => {
  const thatchSegment = useAnalytics()

  const doAppleSignIn = async () => {
    thatchSegment.trackMiscEvent('apple_log_in_started', {})
    try {
      await signInWithApple(authRedirectEventPayload)
    } catch (e) {
      Sentry.captureException(`Error performing apple signin ${e.message}`)
      notify(true, e.message)
    }
  }

  return (
    <ThatchSignInButton
      w={242}
      variant="outline"
      label={label}
      leftIcon={
        <LocalImage
          src={appleLogo}
          width={48}
          height={48}
        />
      }
      onClick={doAppleSignIn}
      {...rest}
    />
  )
}
