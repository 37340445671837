import { ButtonProps } from '@mantine/core'
import { useOs } from '@mantine/hooks'
import * as Sentry from '@sentry/nextjs'
import { useEffect, useState } from 'react'

import { AuthRedirectEventPayload } from '~/analytics/AuthEvent'
import { ThatchSignInButton } from '~/components/shared/ThatchSignInButton'
import { LocalImage } from '~/components/shared/image/LocalImage'
import { notify } from '~/components/shared/notify'
import { useAnalytics } from '~/context/SegmentProvider'
import { signInWithGoogle } from '~/endpoints/user'

import googleLogo from 'public/images/social/google.png'

interface GoogleSignInProps extends Omit<ButtonProps, 'size'> {
  authRedirectEventPayload: AuthRedirectEventPayload
  label: string
}

export const GoogleSignInButton: React.FC<GoogleSignInProps> = ({
  authRedirectEventPayload,
  label,
  ...rest
}) => {
  const thatchSegment = useAnalytics()
  const [isHideButton, setIsHideButton] = useState<boolean>(false)
  const os = useOs()

  useEffect(() => {
    if (os) {
      const isIos = os === 'ios'
      const ua = navigator.userAgent
      const isInstagram = ua.indexOf('Instagram') > -1
      setIsHideButton(isIos && isInstagram)
    }
  }, [os])

  const doGoogleSignIn = async () => {
    thatchSegment.trackMiscEvent('google_log_in_started', {})
    try {
      await signInWithGoogle(authRedirectEventPayload)
    } catch (e) {
      Sentry.captureException(`Error performing google signin ${e.message}`)
      notify(true, e.message)
    }
  }

  return !isHideButton ? (
    <ThatchSignInButton
      w={242}
      variant="outline"
      label={label}
      leftIcon={
        <LocalImage
          src={googleLogo}
          width={48}
          height={48}
        />
      }
      onClick={doGoogleSignIn}
      {...rest}
    />
  ) : null
}
