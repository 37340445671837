import { Button, ButtonProps, Text, createStyles } from '@mantine/core'
import { ReactNode } from 'react'

import { TT_COMMONS_MEDIUM } from '~/theme'

export interface ThatchSignInButtonProps extends ButtonProps {
  label?: string | ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const useStyle = createStyles(theme => ({
  mobileLarge: {
    height: 48,
    paddingLeft: 16,
    paddingRight: 0,
  },
  button: {
    ':disabled': {
      backgroundColor: theme.colors.appPlaceholder,
      color: theme.colors.appWhite,
    },
  },
  leftIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  outline: {
    ':disabled': {
      backgroundColor: 'transparent',
      color: theme.colors.appBlack,
      borderColor: theme.colors.appBlack,
      opacity: 0.5,
    },
  },
  inner: {
    justifyContent: 'start',
  },
}))

export const ThatchSignInButton: React.FC<ThatchSignInButtonProps> = ({
  label,
  className,
  color = 'appBlack.0',
  children,
  onClick,
  variant,
  ...rest
}) => {
  const { classes, cx } = useStyle()

  const buttonRootClassName = cx(
    classes.mobileLarge,
    classes.button,
    className,
    variant === 'outline' ? classes.outline : null
  )

  return (
    <Button
      className={buttonRootClassName}
      classNames={{ leftIcon: classes.leftIcon, inner: classes.inner }}
      radius={24}
      color={color}
      onClick={onClick}
      variant={variant}
      {...rest}
    >
      {label ? (
        <Text
          sx={_theme => ({
            fontSize: '18px',
            fontFamily: `${TT_COMMONS_MEDIUM}`,
            fontWeight: 500,
          })}
        >
          {label}
        </Text>
      ) : (
        children
      )}
    </Button>
  )
}
