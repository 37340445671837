import { Flex, Space } from '@mantine/core'
import { hasLength, isEmail, useForm } from '@mantine/form'
import { getAuth, signOut } from 'firebase/auth'
import { useMemo, useRef, useState } from 'react'
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha-enterprise'

import { AuthRedirectEventPayload, LaunchSource } from '~/analytics/AuthEvent'
import { AppleSignInButton } from '~/components/auth/AppleSignInButton'
import { EmailButton } from '~/components/auth/EmailButton'
import { GoogleSignInButton } from '~/components/auth/GoogleSignInButton'
import { RegisterForm } from '~/components/auth/RegisterForm'
import { TermsAndServices } from '~/components/auth/Tos'
import { useRegisterHomeTitle } from '~/components/auth/hooks/useRegisterHomeTitle'
import { useRegisterHomeSubTitle } from '~/components/auth/hooks/useRegisterSubtitle'
import { notify } from '~/components/shared/notify'
import { Typography } from '~/components/shared/text/Typography'
import { useAnalytics } from '~/context/SegmentProvider'
import { RegisterUser } from '~/endpoints/model'
import { registerUser } from '~/endpoints/user'
import thatchConfig from '~/thatch-config'
import { unknownAvatar } from '~/utils/helper'
import { captureSentryException } from '~/utils/sentry'

type ReCaptcha = typeof ReCAPTCHA

interface RegisterHomeProps {
  isInlined?: boolean
  isInlinePurchase?: boolean
  onSwitchView?: (val: 'login') => void
  onEmailAuthSuccess?: (userUid: string) => Promise<void>
  authRedirectEventPayload: AuthRedirectEventPayload
  launchSource: LaunchSource
  profileName?: string
}

export const RegisterHome: React.FC<RegisterHomeProps> = ({
  isInlined = false,
  onSwitchView,
  onEmailAuthSuccess,
  isInlinePurchase = false,
  authRedirectEventPayload,
  launchSource,
  profileName,
}) => {
  const [currentView, setCurrentView] = useState<'home' | 'register'>('home')
  const [isRegistering, setIsRegistering] = useState(false)
  const thatchSegment = useAnalytics()

  const recaptchaRef = useRef<ReCaptcha>()

  const unknownImage = useMemo(() => {
    return unknownAvatar()
  }, [])

  // extract destination url to be used for redirecting from register page
  const origDestination = useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    const destinationParamVal = params.get('destination')
      ? encodeURIComponent(params.get('destination') as string)
      : undefined ?? ''

    return destinationParamVal ? `?destination=${destinationParamVal}` : ''
  }, [])

  const form = useForm<RegisterUser>({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      photo: unknownImage,
    },
    validate: {
      email: isEmail('Enter a valid email'),
      password: hasLength({ min: 5 }, 'Password must be at least 6 characters'),
      firstName: hasLength({ min: 1 }, 'Enter a First Name'),
      lastName: hasLength({ min: 1 }, 'Enter a Last Name'),
    },
  })

  const handleSubmit = async (values: RegisterUser) => {
    setIsRegistering(true)
    const token = await recaptchaRef.current?.executeAsync()
    const valuesWithToken = { ...values, token }
    thatchSegment.trackMiscEvent('email_log_in_started', {})
    try {
      const newUser = await registerUser(valuesWithToken)
      thatchSegment.trackMiscEvent('email_log_in_completed', {})
      if (!isInlined) {
        await newUser.user?.reload()
      }
      if (newUser.user) {
        thatchSegment.identitfyUser({ userId: newUser.user.uid, email: newUser.user.email })
        await onEmailAuthSuccess?.(newUser.user.uid)
      }
    } catch (error) {
      captureSentryException(error)
      notify(true, error.message)
      await signOut(getAuth())
    } finally {
      setIsRegistering(false)
    }
  }

  const handleSwitchViewClick = () => {
    onSwitchView?.('login')
  }

  const isSupportedContextualAction = useMemo(() => {
    return (
      isInlined &&
      (launchSource == 'add_place' || launchSource == 'save_guide' || launchSource == 'subscribe')
    )
  }, [isInlined, launchSource])

  const homeViewSubTitle = useRegisterHomeSubTitle(
    isInlinePurchase,
    launchSource,
    isSupportedContextualAction
  )

  const homeViewTitle = useRegisterHomeTitle(
    isInlinePurchase,
    launchSource,
    isSupportedContextualAction,
    profileName
  )

  const homeView = (
    <>
      {homeViewTitle}
      {homeViewSubTitle}
      <AppleSignInButton
        mb={16}
        label="Sign up with Apple"
        authRedirectEventPayload={authRedirectEventPayload}
      />
      <GoogleSignInButton
        mb={16}
        label="Sign up with Google"
        authRedirectEventPayload={authRedirectEventPayload}
      />
      <EmailButton
        label="Sign up with Email"
        onClick={() => setCurrentView('register')}
      />

      <Typography
        variant="body2"
        mobileVariant="body2"
        mobileVaraintSource="desktop"
        mb={
          isInlinePurchase
            ? { base: 78, sm: 60 }
            : isSupportedContextualAction
            ? { base: 43, sm: 60 }
            : 96
        }
        mt={isInlinePurchase ? { base: 64, sm: 56 } : 56}
      >
        Already have an account?{' '}
        {isInlined ? (
          <Typography
            span
            color="appFuscia.0"
            variant="button_medium"
            mobileVariant="button_medium"
            mobileVaraintSource="desktop"
            onClick={handleSwitchViewClick}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            Sign in
          </Typography>
        ) : (
          <Typography
            span
            color="appFuscia.0"
            variant="button_medium"
            mobileVariant="button_medium"
            mobileVaraintSource="desktop"
            rawLink
            href={`/login${origDestination}`}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            Sign in
          </Typography>
        )}
      </Typography>
    </>
  )
  const registerView = (
    <>
      <Typography
        variant="h3"
        mobileVariant="h2"
        mb={isInlined ? 56 : 72}
      >
        Sign up{' '}
        <Typography
          span
          variant="h3Italic"
          mobileVariant="h2Italic"
        >
          with email.
        </Typography>
      </Typography>
      <RegisterForm
        form={form}
        handleSubmit={handleSubmit}
        isRegistering={isRegistering}
      />
      <Space h={32} />
      <Typography
        mb={56}
        variant="button_medium"
        mobileVariant="button_medium"
        mobileVaraintSource="desktop"
        color="appFuscia.0"
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          form.reset()
          setCurrentView('home')
        }}
      >
        ← All sign up options
      </Typography>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={thatchConfig.reCaptchaKey}
      />
    </>
  )

  let paddingTop = 120
  if (isInlinePurchase || isSupportedContextualAction) {
    paddingTop = 54
  } else if (isInlined) {
    if (currentView == 'register') {
      paddingTop = 72
    } else {
      paddingTop = 96
    }
  }

  return (
    <>
      <Flex
        pt={paddingTop}
        pb={isInlined ? { base: 15, sm: 40 } : 134}
        direction="column"
        align="center"
        justify="center"
        w="100%"
      >
        <Flex
          w="100%"
          direction="column"
          align="center"
          justify="center"
        >
          {currentView == 'home' ? homeView : registerView}
          <TermsAndServices source="Sign up" />
        </Flex>
      </Flex>
    </>
  )
}
